//
// print.scss
//

// Used invoice page
@media print {

  // .ant-table-wrapper {
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }

  // .ant-table-body {
  //   max-height: max-content !important;
  //   overflow-y: auto;
  // }

  body {
    padding: 0;
    margin: 0;
  }

  @page {
    size: auto;
    /* auto is the initial value */
    margin: 0;
    width: 100%;
  }

  #toPdf {
    width: auto !important;
  }

  .no-print {
    display: none;
  }

  .printme {
    display: block;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}