.Reports-dashboard {
  .Reports-dashboard-menu {
    padding: 10px 10px;
    margin: 10px;

    .Reports-dashboard-item {
      height: 80px;

      padding: 1.24rem 1rem;
      // color: #000000;
      // background-color: #fff;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      position: relative;

      transition: all 0.4s;

      .title-box {
        font-size: 15px;
        padding: 0.45rem 0;

        text-transform: uppercase;
      }

      .view-box {
        text-align: center;
        align-items: center;

        a {
          padding: 0.2rem 0.2rem;
          margin: 0.2rem 0.2rem;
          width: 45px;
          color: #000000;

          border-radius: 0.25rem;

          align-items: center;

          i {
            font-size: 1.5rem;
            line-height: 2rem;
            text-align: center;
            color: #000000;
            transition: all 0.4s;
          }

          &:hover {
            color: rgb(255, 255, 255) !important;
            background: @theme-color;

            i {
              color: rgb(255, 255, 255) !important;
            }
          }
        }
      }

      &:hover {
        color: #5664d2 !important;
        transform: scale(1.05);
        border: 0.012rem solid #5664d2;

        i {
          color: #5664d2;
        }
      }
    }
  }
}