.@{class-prefix}-setting-menu {
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid @b-40;
  min-height: 930px;
  background: @theme-color;
  border-radius: 5px 0 0 5px;

  .ant-card-body {
    padding: 0px 0px 0px 15px;
}

  .@{class-prefix}-item-height {
    height: 30px !important;
  }
  .@{class-prefix}-menu-sub-title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .@{class-prefix}-setting-menu-body {
    background: @theme-color;
    a {
      color: #ffffff;
      // color: #0010f7 !important;
      font-weight: 400;
    }

    .menu-item-selected {
      background-color: #fff;
      border-radius: 5px 0 0 5px;
      a {
        color: @primary-1;
        font-weight: 400;
      }
    }

    a:hover {
      color: @primary-1;

    }

    .menu-item-selected-in-active:hover {
      background-color: #fff;
      border-radius: 5px 0 0 5px;
    }
  }

//   .ant-menu-item-selected {
//     background-color: #ffffff;
//     border-radius: 5px 0px 0px 5px;
//     a {
//       color: #0010f7 !important;
//       font-weight: 400;
//     }
// }

  .ant-menu-inline .ant-menu-item::after{
    border-right: 0px solid #0010f7;
  }

  &-header {
    .ant-scroll-number {
      bottom: -7px;
      right: 6px;
      top: auto;
      padding: 0 3px;
      height: 16px;
      line-height: 16px;
      z-index: 1;
      transform: translate(0px, 0px);
      box-shadow: none;
    }

    .ant-avatar {
      overflow: initial;

      &:before {
        content: "";
        position: absolute;
        top: -7px;
        left: -7px;
        right: -7px;
        bottom: -7px;
        background: conic-gradient(@primary-1, #fff0);
        border-radius: 100%;
        pointer-events: none;
      }

      &:after {
        content: "";
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        background: @b-0;
        border-radius: 100%;
        pointer-events: none;
      }

      img {
        position: relative;
        z-index: 1;
      }
    }
  }

  &-body {
    margin-top: 50px !important;

    .is-active {
      .ant-row {
        color: @primary-1;
      }

      svg {
        fill: @primary-1;
      }
    }
  }

  @media @md {
    display: none;
  }

  .ant-spin-dot-item {
    background-color: #fff;
  }
}

.@{class-prefix}-profile-content-list {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      align-items: center;

      span {
        display: block;

        &:nth-child(1) {
          min-width: 230px;
        }
      }

      @media @sm {
        display: block;

        span {
          &:nth-child(1) {
            min-width: initial;
          }
        }
      }
    }
  }
}

.@{class-prefix}-profile-notifications {
  max-width: 424px;
}

.@{class-prefix}-profile-action-btn {
  @media @sm {
    order: 1;

    button {
      width: 100%;
      margin-top: 24px;
    }
  }
}

.@{class-prefix}-profile-connect-btn {
  flex: 0 0 360px;

  svg {
    min-width: 14px;
  }

  @media @sm {
    flex: 0 0 100%;
  }
}

.@{class-prefix}-profile-mobile-menu-btn {
  display: none;

  @media @md {
    display: block;

  }
}

.@{class-prefix}-profile-mobile-menu {
  .ant-drawer-header {
    padding: 16px;
    margin: 0;
    border: none;
    color: #fff;
    text-align: center;
    background-color: @primary-0;
  }

  .ant-drawer-body {
    padding: 0;
    background-color: @primary-0;
  }

  .@{class-prefix}-setting-menu {
    padding: 0 0 35px !important;
    display: block;
    min-height: initial;
  }
}

.@{class-prefix}-profile-activity {
  .ant-table-wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

// RTL
html[dir="rtl"] {
  .@{class-prefix}-setting-menu {
    border-right: none;
    border-left: 1px solid @b-40;
  }
}