@import "error/index.less";
@import "authentication/index.less";
@import "profile/index.less";
@import "vehicleCounterSales/index.less";
@import "stockInOut/index.less";
@import "transaction/index.less";
@import "settings/index.less";
@import "stock/index.less";
@import "managerUsers/index.less";
@import "masters/index.less";
@import "reports/index.less";
@import "pettyCash/index.less";

.@{class-prefix}-mobile-none {
    @media @sm {
        display: none;
    }
}

.@{class-prefix}-isMobile {
    display: none;

    @media @sm {
        display: block;
    }
}

// ::-webkit-scrollbar {
//     width: 7px;
// }
// ::-webkit-scrollbar-track {
//     background-color: #ebebeb;
//     -webkit-border-radius: 10px;
//     border-radius: 10px;
// }
// ::-webkit-scrollbar-thumb {
//     -webkit-border-radius: 7x;
//     border-radius: 7px;
//     background: #c1c1c1;
// }


.ant-table.ant-table-middle .ant-table-tbody>tr>td {
    padding: 7px 10px;
}

.ant-table.ant-table-middle .ant-table-thead>tr>th {
    padding: 10px 10px;
}

.@{class-prefix}-text-color-white {
    color: #ffffff;
}
.disable-color:disabled {
    background-color: #ffffff !important;
}